.shoppable-slider-wrapper {
  .swiper-slide-prev,
  .swiper-slide-next {
    @apply 2xl:h-[500px];
    @media screen and (max-width: 1296px) {
      transform: scale(0.9) translateZ(0) !important;
    }
  }
  .swiper-slide-active {
    @apply 2xl:h-[600px] #{!important};
  }

  .swiper-slide {
    @apply my-auto transition-all duration-75 2xl:h-[500px];
    @media screen and (max-width: 768px) {
      @apply w-[320px];
    }
  }
}
