.faq-search-results {
  @media(min-width:1024px) {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 16px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 8px;
      padding: 2px;
      border: 4px solid #f7f9fa;
      background-color: #e6ecef;
    }
  }
}

.hide-overflow-mobile {
  @media screen and (max-width: 1024px) {
    &::-webkit-scrollbar {
      @apply opacity-0 h-0 w-0
    }
  }
}